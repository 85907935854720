<template>
    <div>         
        <RegisterForm />
    </div>    
</template>
<script>
    import RegisterForm from '../components/registration.vue'
    export default {
        components: {
            RegisterForm
        }
    }
</script>
<template>
    <div>
        <div v-for="(sub, index) in submissions" v-bind:key="index">      
            <h3 class="mb-3 mt-4">{{sub.title}}</h3>  
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr class="text-center">
                            <th scope="col">#</th>
                            <th scope="col">Projektname</th>
                            <th scope="col">E-Mail</th>
                            <th scope="col">Datum</th>                       
                            <th scope="col">Akzeptiert</th>
                            <th scope="col">Bewertet</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tsub, tind) in sub.submissions" v-bind:key="tind" v-bind:style="tsub.oldrecord ? 'background-color: #E8E8E8;' : ''">
                            <td class="text-center">{{tsub.id}}</td>
                            <td><a target="_blank" v-bind:href="'/survey/'+tsub.survey_id+'/'+tsub.mid+'/'+tsub.id">{{tsub.project}}</a></td>
                            <td><a v-bind:href="'mailto:'+tsub.email">{{tsub.email}}</a></td>
                            <td>{{getDate(tsub.submitted)}}</td>
                            <td>
                                <select class="form-select" v-model="tsub.accepted" v-on:change="changeState(tsub)" v-bind:disabled="tsub.oldrecord">
                                    <option value="0">-</option>
                                    <option value="1">Ja</option>
                                    <option value="2">Nein</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <input type="text" class="form-control" placeholder="Punkte" v-model="tsub.score" v-on:change="changeScore(tsub)" v-bind:disabled="tsub.oldrecord">
                            </td>
                        </tr>               
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from '../axios'
    import msg from '../messages'
    import checkLogin from "../scripts/checkLogin";
    export default {
        data: function(){
            return {
                submissions: []
            }
        },
        beforeMount: async function(){
           try{               
               const data = await axios.post('getSubmissionList', {});
               if(data.status !== 200 || typeof data.data.submissions !== "object"){
                   throw new Error("Could not load");
               }               
               this.submissions = data.data.submissions;              
               this.submissions.sort(
                   (a, b) => {
                       return a.order - b.order;
                   }
               );
               for(let i in this.submissions){ 
                   this.submissions[i].submissions.sort(
                       (a, b) => {
                           return a.id - b.id;
                       }
                   );                  
                   const foundArray = [];
                   for(let u = this.submissions[i].submissions.length - 1; u >= 0; u--){
                       const submission = this.submissions[i].submissions[u];
                       let found = false;
                       for(let t in foundArray){
                           if(foundArray[t] === submission.mid){
                               found = true;
                           }
                       }
                       if(found === false){
                           foundArray.push(submission.mid);
                           submission.oldrecord = false;
                       } else {
                           submission.oldrecord = true;
                       }
                   }                   
               }     
           } catch(error){                
                this.$store.commit('showWarning', {message: msg.unknown, time:true});
           }
        },
        methods: {
            getDate: function(date){
                const d = new Date(date);
                return d.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).toString();
            },
            changeScore: async function(sub){
                try{
                    await checkLogin(this.$router, this.$store);
                    if(sub.score === "" || sub.score === null){
                        sub.score = null;
                    } else {
                        const score = parseInt(sub.score);                
                        if(isNaN(score)){                    
                            sub.score = null;                            
                            this.$store.commit('invalidParam', {message: msg.unknown, time:true});
                            return;
                        }
                        sub.score = score;
                    }                   
                    const data = await axios.post('changeSubmissionState', sub);                    
                    if(data.status !== 200 || data.data.success !== true){
                        throw new Error('Could not change');
                    }
                } catch(error){                    
                    this.$store.commit('showWarning', {message: msg.unknown, time:true});
                }                
            },
            changeState: async function(sub){
                try{
                    await checkLogin(this.$router, this.$store);
                    const accepted = parseInt(sub.accepted);
                    if(isNaN(accepted) || accepted < 0 || accepted > 2){                        
                        this.$store.commit('invalidParam', {message: msg.unknown, time:true});
                        return; 
                    }
                    sub.accepted = accepted;
                    const data = await axios.post('changeSubmissionState', sub);                   
                    if(data.status !== 200 || data.data.success !== true){
                        throw new Error('Could not change');
                    }
                } catch(error){
                    this.$store.commit('showWarning', {message: msg.unknown, time:true});
                }                
            }
        }
    }
</script>
<template>
    <div>
        <LoginForm />        
    </div>
</template>
<script>
    import LoginForm from '../components/loginform.vue';

    export default {
        components: {
            LoginForm            
        }
    }
</script>
const text = {
    registered: "Du wurdest erfolgreich registriert. Eine E-Mail zur Verifizierung wurde Dir zugesendet.",
    verified: "Du hast Dich erfolgreich verifiziert und kannst Dich nun einloggen.",
    couldNotVerify: "Leider konntest Du nicht verifiziert werden. Versuche es noch einmal oder wende Dich an das Team.",
    missingInput: "Du hast nicht alle nötigen Eingaben getätigt!",
    unknown: "Ein unbekannter Fehler ist aufgetreten. Bitte wende Dich an unser Team!",
    noconnection: "Es konnte keine Verbindung zum Server hergestellt werden!",
    couldNotChange: "Änderungen konnten nicht übernommen werden!",
    changed: "Änderungen wurden übernommen!",
    invalidParam: "Fehlerhafte Eingaben!",
    reset: "Dir wurde eine E-Mail mit neuem Passwort zugesendet!",
    login: {
        data: "Du hast nicht alle nötigen Eingaben getätigt!",
        nologin: "Du bist nicht angemeldet!",
        noadmin: "Du bist kein Administrator!",
        blocked: "Dein Benutzerkonto ist blockiert. Bitte wende Dich an unser Team!",
        noverified: "Du bist noch nicht freigeschaltet. Bitte schau in Dein E-Mail-Konto nach einer E-Mail zur Verfizierung!",
        userorpassword: "Benutzername oder Passwort falsch!",
        unknown: "Ein unbekannter Fehler ist aufgetreten. Bitte wende Dich an unser Team!"
    },
    registration: {       
        success: "Du wurdest erfolgreich registriert. Eine E-Mail zur Verifizierung wurde Dir zugesendet.",
        email_exist: "Die E-Mail-Adresse ist bereits in Verwendung!",
        project_exist: "Das Projekt existiert bereits!",
        invalidemail: "Du hast keine valide E-Mail-Adresse eingegeben!",
        missing: "Du hast nicht alle nötigen Eingaben getätigt!",
        invalidInp: 'Fehlerhafte Eingaben!'
    },
    surveyList: {
        error: "Die Liste konnte nicht geladen werden. Bitte wende Dich an unser Team!"
    },
    survey: {
        error: "Der Fragebogen konnte nicht verarbeitet werden. Bitte wende Dich an unser Team!",
        couldNotSave: "Deine Eingaben konnten nicht an den Server übertragen werden. Bitte wende Dich an unser Team!",
        couldNotLoad: "Deine Eingaben konnten nicht vom Server geladen werden. Bitte wende Dich an unser Team!"
    },
    submitSurvey: {
        success: "Deine Einreichung wurde übertragen!",
        error: "Ein unbekannter Fehler ist aufgetreten. Bitte wende Dich an unser Team!",
        missing: "Du hast nicht alle nötigen Eingaben getätigt!",
        change: "Deine Antworten wurden nicht überarbeitet. Du musst erst etwas bei Deinen Eingaben ändern!"
    },
    upload: {
        error: "Die Datei konnte nicht hochgeladen werden. Bitte wende Dich an unser Team!",
        success: "Die Datei wurde erfoglreich hochgeladen!",
        size: "Die ausgewählte Datei ist zu groß! Erlaubt sind max. 150 MB.",
        format: "Das Format der Datei ist nicht erlaubt. Erlaubt sind Bilder, Videos und Dokumente!"

    },
    changesSaved: "Deine Änderungen wurden gespeichert!"
};

export default text;

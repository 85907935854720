<template>
    <div class="card">
        <div class="card-body">
            <h3 class="card-title">Passwort vergessen</h3>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="email">E-Mail</label>
                    <input type="email" class="form-control" v-model="email" id="email" aria-describedby="emailHelp" placeholder="Deine E-Mail-Adresse" />
                </div>
                <div class="form-group mx-2 mt-1 col">
                    <button type="button" v-on:click="submit" class="btn btn-primary"> Reset </button>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
    import msg from '../messages';
    import axios from "../axios";
    export default {
        data: function(){
            return {
                email: ''
            }
        },
        methods: {
            submit: async function(){
                try{
                    if(typeof this.email !== "string" || this.email.length <= 0){
                        this.$store.commit('showWarning', {message: msg.missingInput, time:true}); 
                    }
                    const p = await axios.post('resetPassword', {email: this.email});
                    if(p.status !== 200 || p.data.success !== true){
                        this.$store.commit('showWarning', {message: msg.unknown, time:true}); 
                    } else {
                        this.$store.commit('showSuccess', {message: msg.reset, time:true}); 
                    }                   
                } catch(error){
                    this.$store.commit('showWarning', {message: msg.unknown, time:true}); 
                }               
            }
        }
    }
</script>
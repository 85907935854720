<template>
    <div>
        <h3 class="mb-3">Projekte</h3>
        <div class="table-responsive">        
            <table class="table">
                <thead>
                    <tr class="text-center">
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Projekt</th>
                        <th scope="col">E-Mail</th>
                        <th scope="col">Verifiziert</th>
                        <th scope="col">Geblockt</th>
                        <th scope="col">Admin</th>
                        <th scope="col">Erstellt</th>
                        <th scope="col">Letzter Login</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user, index) in users" v-bind:key="index">
                        <th scope="row">{{user.id}}</th>
                        <td>{{user.name}}</td>
                        <td>{{user.project}}</td>
                        <td><a v-bind:href="'mailto:'+user.email">{{user.email}}</a></td>
                        <td class="text-center"><input class="form-check-input" type="checkbox" v-bind:checked="user.verified" v-model="user.verified" v-on:change="changeState(user)"></td>
                        <td class="text-center"><input class="form-check-input" type="checkbox" v-bind:checked="user.blocked" v-model="user.blocked" v-on:change="changeState(user)"></td>
                        <td class="text-center"><input class="form-check-input" type="checkbox" v-bind:checked="user.admin" v-model="user.admin" v-on:change="changeState(user)"></td>
                        <td>{{user.created}}</td>
                        <td>{{user.last_login}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
    import axios from '../axios'
    import msg from '../messages'
    import checkLogin from "../scripts/checkLogin";
    export default {
        data: function(){
            return {
                users: []
            }
        },
        beforeMount: async function(){
            try{                
                const data = await axios.post('getUserList', {});
                if(data.status === 200 && typeof data.data === "object"){
                    for(let i in data.data.data){
                        const entry = data.data.data[i];
                        entry.created = new Date(entry.created);
                        entry.created = entry.created.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).toString(); 
                        entry.last_login = new Date(entry.last_login);
                        entry.last_login = entry.last_login.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).toString();                       
                        this.users.push(entry);
                    }  
                    this.users.sort(
                        (a, b) => {
                            return a.id - b.id;
                        }
                    )                  
                } else {
                    throw Error('Whatever');
                }
            } catch(error){
                this.$store.commit('showWarning', {message: msg.unknown, time:true});
            }           
        },
        methods: {
            changeState: async function(object){
                try{                          
                    await checkLogin(this.$router, this.$store);            
                    const data = await axios.post('changeUserState', object);
                    if(data.status !== 200 || data.data.success !== true){
                        throw new Error('Could not change');
                    }                    
                } catch(error){                    
                    this.$store.commit('showWarning', {message: msg.couldNotChange, time:true});
                }                
            }
        }
    }
</script>
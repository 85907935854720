<template>
  <div id="app" class="container mt-3 mb-3">    
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3">
        <a class="navbar-brand" href="#">
          <img src="./assets/logo-mammut.png" alt="" height="80" class="d-inline-block align-middle">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link" v-if="!getLoginStatus">Home</router-link>
              <router-link to="/overview" class="nav-link" v-if="getLoginStatus">Von der Idee zum Startup</router-link>            
            </li>      
            <li class="nav-item" v-if="!getLoginStatus">
              <router-link to="/login" class="nav-link" >Einloggen / Registrieren</router-link>                         
            </li>     
            <li class="nav-item">
              <router-link to="/admin" class="nav-link" v-if="getAdminStatus">Administration</router-link>
            </li>
            <!--<li class="nav-item">
              <router-link to="/about" class="nav-link">Über</router-link>           
            </li>-->
          </ul>          
          <div class="navbar-nav ml-auto" v-if="getLoginStatus">            
            <div class="nav-item dropdown show">
              <a href="#" data-toggle="dropdown" class="nav-link dropdown-toggle user-action" aria-expanded="true">
                {{getEmail}} <b class="caret"></b>
              </a>
              <div class="dropdown-menu">
                <router-link to="/profile" class="dropdown-item"><font-awesome-icon icon="user" />&nbsp;&nbsp;Profil</router-link>                   
                <div class="dropdown-divider"></div>
                <a href="#" class="dropdown-item" v-on:click="logoutUser"><font-awesome-icon icon="power-off" />&nbsp;&nbsp;Abmelden</a>
              </div>
            </div>
          </div>
        </div>
      </nav>  
      <div class="alert" v-bind:class="getAlertType" role="alert" v-if="showAlert">
        {{getAlertMessage}}
      </div>      
      <div class="mx-3">        
        <router-view />      
        <footer class="hr mt-3">
          <p class="float-right"><a href="#" v-on:click="window.scrollTo(0,0)">Back to top</a></p>
          <p><router-link to="/about">Impressum</router-link> · <router-link to="/privacy">Datenschutz</router-link></p>
        </footer> 
      </div> 
  </div>
</template>
<script>  
  export default {
    data: function(){
      return {
        
      }
    },
    computed: {
      getLoginStatus: function(){        
        return this.$store.getters.isLoggedIn;
      },
      getAdminStatus: function(){
        return this.$store.getters.isAdmin;
      },     
      getEmail: function(){
        return this.$store.getters.getEmail;
      },
      showAlert: function(){
        return this.$store.getters.getAlertStatus;
      },
      getAlertMessage: function(){
        return this.$store.getters.getAlertMessage;
      },
      getAlertType: function(){
        return this.$store.getters.getAlertType;
      }
    },
    methods: {
      logoutUser: function(){
        //TODO
        this.$store.commit("logoutUser");
        this.$router.push({ path: "/" });
      }
    }  
  }
</script>

<style>
</style>

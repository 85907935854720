<template>   
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Einloggen</h4>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="email">E-Mail</label>
                    <input type="email" class="form-control" v-model="email" id="email" aria-describedby="emailHelp" placeholder="Deine E-Mail-Adresse" />
                </div>
                <div class="form-group col-md-6">
                    <label for="exampleInputEmail1">Passwort </label>
                    <input type="password" class="form-control" v-model="password" id="password" aria-describedby="passwordHelp" placeholder="Dein Passwort" />
                </div>
                <div class="form-group mx-2 mt-1 col">
                    <button type="button" v-on:click="submit" class="btn btn-primary"> Anmelden </button>
                    <div class="ml-1 float-right">          
                        <router-link to="/reset">Passwort vergessen?</router-link><br />  
                        <router-link to="/register">Neu registrieren</router-link>  
                    </div>
                </div>             
            </div>
        </div>          
    </div>   
</template>
<script>

    import axios from "../axios"
    import msg from '../messages'
    import crypto from 'crypto'

    export default {
        data: function(){
            return{
                email: "",
                password: ""
            }
        },
        methods: {
            submit: function(){
                const hash = crypto.createHash("sha512");
                const pwd = hash.update(this.password, "utf-8").digest("hex");               
                axios.post('login', {email: this.email, password:pwd}).then(
                    (resolve) => {                                                                      
                        if(resolve.status !== 200){
                            this.$store.commit('showWarning', {message: msg.noconnection, time:true});
                            return;
                        }     
                        if(typeof resolve.data !== "object" || typeof resolve.data.login !== "string" || resolve.data.login.length <= 0){                            
                            this.$store.commit('showWarning', {message: msg.noconnection, time:true});
                            return;
                        }  
                        if(resolve.data.login === 'ok'){                                                     
                            this.$router.push({ path: "overview" }); 
                            return;                          
                        } else {                           
                            if(typeof msg.login[resolve.data.login] === "undefined"){
                                this.$store.commit('showWarning', {message: msg.unknown, time:true});
                            } else {
                                this.$store.commit('showWarning', {message: msg.login[resolve.data.login], time:true});
                            }                        
                        }  
                    },
                    () => {
                        this.$store.commit('showWarning', {message: msg.noconnection, time:true});                        
                        return;
                    }
                );
            }
        }
    }
</script>
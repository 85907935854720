import axios from "../axios"

export default async function checkLogin(router, store){
    const out = await axios.post('checkLogin', {});
    if(out.status === 200 && out.data.login === "ok"){        
        store.commit("loginUser", {admin: out.data.admin, name: out.data.name, email: out.data.email, project: out.data.project});
        return true;
    } else {
        store.commit("logoutUser");
        router.push({ path: "/" });
    }
    return false;
}
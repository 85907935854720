import axios from 'axios'

const instance = axios.create(
    {
        baseURL: 'https://tester.mammut-startupfestival.de/',
        withCredentials: true,
        timeout: 4000,
        headers: {
            'Content-Type': 'application/json'   
        }
    }
);

export default instance;
<template>
    <div> 
    <h3 class="text-left">Von der Idee zum Startup</h3>
    <p class="text-left">
        Starte nun den Mammut Startup Checker und durchlaufe mit Deiner Idee die fünf aufeinander aufbauenden Phasen. 

        Bitte beachte, dass Du keine Phase überspringen und erst durch einen erfolgreichen Abschluss (der momentan durchlaufenden) zur nächsten gelangen kannst. 
        Ob Du mit der nächsten Phase starten kannst, siehst Du in der Spalte “Verfügbar”.<br />

        Durch direktes Feedback (per E-Mail) weißt Du sofort, an welchen Stellen Du Deine Idee noch verfeinern solltest. 
        Die folgende Tabelle zeigt Dir genau an, in welcher Phase Du Dich gerade mit deiner Idee befindest. 
        Die Spalte "Eingereicht" zeigt Dir an, ob Du die Fragen einer Phase vollständig beantwortet und erfolgreich abgeschickt hast. 
        Ist dies der Fall, werden Deine Antworten von uns überprüft und bewertet. Wurde Deine Einreichung von uns akzeptiert, ist Deine Idee bereit für die nächste Phase, was durch einen grünen Punkt in der Spalte "Akzeptiert" gekennzeichnet wird.. 
        Sofern die Stufen noch grau dargestellt sind, so bedeutet dies, dass diese Felder noch nicht freigeschaltet/bearbeitet sind. 
        Hast Du mit Deiner Idee alle vier Phasen erfolgreich durchlaufen, erhältst Du einen Gesamt-Punktestand (Score). <br /><br />

        Falls Du technische Probleme oder Rückfragen haben solltest kannst Du Dich unter: <a href="mailto:mammut@fh-swf.de">mammut@fh-swf.de</a> bei uns melden. 
        <br /><br />
        Vielen Dank für Deine Teilnahme! <br />
        <i>Viel Spaß und let’s check!</i>

    </p>    
    <div class="table-responsive">
        <table class="table mb-3 text-center">
            <thead class="thead-dark">
                <tr>
                <th scope="col">#</th>
                <th scope="col">Aktivität</th>
                <th scope="col">Verfügbar</th>
                <th scope="col">Eingereicht</th>
                <th scope="col">Akzeptiert</th>
                <th scope="col">Score</th>
                </tr>
            </thead>
            <tbody v-if="surveyList.length > 0">
                <OverviewEntry v-for="(survey, index) in surveyList" v-bind:key="index" v-bind:survey="survey" />
            </tbody>
        </table>
    </div>
    </div>
</template>
<script>

    import OverviewEntry from "../components/surveyListEntry.vue";
    import checkLogin from "../scripts/checkLogin";
    import axios from "../axios";
    import msg from "../messages";

    export default {
        data: function () {
            return {
               surveyList: []
            };
        },
        beforeCreate: async function(){
            await checkLogin(this.$router, this.$store);       
            let surveyList = await axios.post("getSurveyList", {});
            if(surveyList.status !== 200 || typeof surveyList.data.survey !== "object"){
                this.$store.commit('showAlert', {message: msg.surveyList.error, time:true});
                return;
            }       
            surveyList = surveyList.data.survey;                          
            this.surveyList = surveyList;
        },
        methods: {
           
        },
        computed: {
            
        },
        components: {
            OverviewEntry
        }
    }
</script>
<template>
    <div>Verify</div>
</template>
<script>
    import msg from '../messages';
    import axios from '../axios';

    export default {
        beforeCreate: async function(){            
            try{
                let vkey = this.$route.params.id;
                if(typeof vkey !== "string" && typeof vkey !== "number") throw new Error("Invalid Format.");    
                if(typeof vkey === 'number'){
                    vkey = vkey.toString();
                }           
                const d = await axios.post('verifyUser', {vkey: vkey});
                if(d.status !== 200 || d.data.success !== true){
                  throw new Error('unknown');  
                }
                this.$store.commit('showSuccess', {message: msg.verified, time:true}); 
            } catch(error){
                this.$store.commit('showWarning', {message: msg.couldNotVerify, time:true});    
            } finally {
                this.$router.push({ path: "/" });
            }
        }
    }
</script>

<template>
    <div>
        <UserManagement />
        <SurveyList />
    </div>
</template>
<script>
    import UserManagement from "../components/usermanagement.vue";
    import SurveyList from "../components/surveyListAdmin.vue";
    import checkLogin from "../scripts/checkLogin";
    export default {
        data: function(){
            return {
                    
            }
        },
        beforeCreate: async function(){
            await checkLogin(this.$router, this.$store);
            if(!this.$store.getters.isAdmin){               
                this.$router.push({ path: "/" });
            }
        },
        components: {
            UserManagement,
            SurveyList
        }
    }
</script>
<template>
    <div>        
        <h3>Mammut Startup Checker</h3>
        <p>
            Du hast das Ziel, mit Deinem Start-up groß und erfolgreich zu werden? Allerdings fragst Du Dich, ob Dein Start-up das Zeug zum Durchstarten hat? <b>Dann lass <router-link to="/register">Deine Idee von unserem Mammut Startup Checker testen</router-link>.</b>.
        </p>
        <p>
            Bei dem Mammut Startup Checker wirst du durch vier Phasen: Entdeckung, Validierung, Entwicklung eines Business Models und Effizienz geführt. Anhand des Mammut Startup Checkers sollst Du erfahren, in welchen der Kerndimensionen eines erfolgreichen Start-ups: Produkt, Team, Geschäftsmodell und Finanzierung Du bereits stark bist und an welchen Stellen Dein Wachstum noch erhöht werden kann.
        </p>       
        <p>
            Einfach Deine Idee als Kurzbeschreibung (max. 100 Wörter) oder Video (max. 1 min; max. 150 MB im Dateiformat .mp4) einreichen und anschließend wirst Du mittels des digitalen Tools zu den jeweiligen Phasen zugelassen. Also nichts wie los und viel Spaß! 
        </p>      
    </div>
</template>
<script>   
    export default {
        
    }
</script>
<template>
    <div class="form-group">
        <label>[{{data.id}}]: {{data.text}}</label>
        <textarea class="form-control" v-bind:id="data.id" rows="3" v-model="text" maxlength="1000" style="height:200px;" v-on:blur="submit" v-bind:readonly="readonly"></textarea>
        <small class="form-text text-muted pl-1">{{getChar}} / {{maxChars}} Zeichen</small>
  </div>
</template>

<script>
import axios from "../axios";
import msg from "../messages";
import checkLogin from "../scripts/checkLogin";
export default {   
    props: ["data", "readonly", "userId", "submissionId"],
    data: function(){
        return {
            text: '',
            maxChars: 1000
        }
    },
    computed:{
        getChar: function(){
            return this.text.length;
        }
    },
    beforeMount: async function(){     
        if(this.data.id) axios.post('getText', {question: this.data.id, userId: this.userId, sid:this.submissionId}).then(
            (res) => {
                if(res.status === 200 && typeof res.data.answer !== "undefined"){                   
                    this.text = res.data.answer;
                } else {
                     this.$store.commit("showAlert", {message: msg.survey.couldNotLoad, time:true});
                }
            },
            () => {
                this.$store.commit("showAlert", {message: msg.survey.couldNotLoad, time:true});
            } 
        )
    },
    methods: {
        submit: async function(){
            await checkLogin(this.$router, this.$store);
            if(this.readonly === true) return;
            if(typeof this.text === "string" && this.text.length > 0){
                axios.post('setText', {question: this.data.id, text: this.text}).then(
                    (response) => {
                        if(response.status !== 200 || response.data.commit !== "accepted"){
                            this.$store.commit("showAlert", {message: msg.survey.couldNotSave, time:true});
                        }
                    },
                    () => {
                        this.$store.commit("showAlert", {message: msg.survey.couldNotSave, time:true});
                    }
                )
            }
        }
    }
}
</script>

<style>
  label{
    font-style:italic;
  }
</style>


<template>
    <div class="card">
        <div class="card-body">
            <h3 class="card-title">Registrierung</h3>           
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="project">Projektname</label>
                    <input type="text" v-model="project" class="form-control" id="project" aria-describedby="projectHelp" placeholder="Dein Projektname"/>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="name">Vorname</label>
                    <input type="text" v-model="name" class="form-control" id="name" aria-describedby="nameHelp" placeholder="Dein Vorname"/>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="email">E-Mail-Adresse</label>
                    <input type="email" v-model="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Deine E-Mail-Adresse" />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="password">Passwort</label>
                    <input type="password" v-model="password" class="form-control" id="password" aria-describedby="passwordHelp" placeholder="Dein Passwort" />
                  </div>
                </div>
                <div class="form-group px-1 mt-2">
                  <button type="button" v-on:click="submit" class="btn btn-primary">Absenden</button>
                </div>
        </div>
    </div>
</template>
<script>
    import axios from "../axios"
    import msg from '../messages'
    import crypto from 'crypto'
    export default {
        name: "RegisterForm",
        data: function () {
            return {
                name: "",
                project: "",
                email: "",
                password: ""
            };
        },
        methods: {
            submit: function () {
              try{             
                if(
                  typeof this.name !== "string" || this.name.length <= 0 || this.name.length > 50 ||
                  typeof this.project !== "string" || this.project.length <= 0 || this.project.length > 255 ||
                  typeof this.email !== "string" || this.email.length <= 0 || this.email.length > 255 ||
                  typeof this.password !== "string" || this.password.length <= 0
                ){
                  this.$store.commit('showWarning', {message: msg.registration.invalidInp, time:true});
                  return;
                }
                const hash = crypto.createHash("sha512");
                const pwd = hash.update(this.password, "utf-8").digest("hex");
                axios.post('register', {name: this.name, project: this.project, email: this.email, password: pwd}).then(
                  (resolve) => {
                    if(resolve.status !== 200){
                      this.$store.commit('showWarning', {message: msg.noconnection, time:true});
                      return;
                    }     
                    if(typeof resolve.data !== "object" || typeof resolve.data.register !== "string" || resolve.data.register.length <= 0){                            
                      this.$store.commit('showWarning', {message: msg.noconnection, time:true});
                      return;
                    }
                    if(resolve.data.register === 'ok'){
                      this.$store.commit('showSuccess', {message: msg.registered, time:true});
                      return;                          
                    } else {
                      if(typeof msg.registration[resolve.data.register] === "undefined"){
                        this.$store.commit('showWarning', {message: msg.unknown, time:true});
                      } else {
                        this.$store.commit('showWarning', {message: msg.registration[resolve.data.register], time:true});
                      }                        
                    }  
                  },
                  () => {
                    this.$store.commit('showWarning', {message: msg.noconnection, time:true});
                    return;
                  }
                )
              } catch(error){
                this.$store.commit('showWarning', {message: msg.unknown, time:true});
              }
            }
        }
    }
</script>
<template>
    <div>       
        <h1>Impressum</h1>
        <p>Fachhochschule Südwestfalen<br>Baarstraße 6<br>58636 Iserlohn</p>
        <p>Telefon: +492371/566-0<br>Telefax: +492371/566-274<br>E-Mail: <a href="mailto:info@fh-swf.de" target="_blank" rel="noopener noreferrer nofollow" class="external-link">info@fh-swf.de</a><br>Internet: <a href="http://www.fh-swf.de/" target="_blank" rel="noopener noreferrer nofollow" class="external-link">http://www.fh-swf.de/</a></p>
        <p>Die Fachhochschule Südwestfalen ist eine Körperschaft des Öffentlichen Rechts. Sie wird durch den amtierenden Rektor vertreten.</p>
        <h4>Zuständige Aufsichtsbehörde:</h4>
        <p>Ministerium für Kultur und Wissenschaft des Landes Nordrhein-Westfalen<br>Völklinger Straße 49<br>40221 Düsseldorf</p>
        <h4>Umsatzsteuer-Identifikationsnummer</h4>
        <p>gemäß § 27 a Umsatzsteuergesetz: DE 125.580.587</p>
        <h4>Inhaltlich Verantwortlicher</h4>
        <p>gemäß § 6 Absatz 2 MDStV: <a href="https://www4.fh-swf.de/de/home/beschaeftigte/organisation_1/hochschulleitung/praesident/index.php" target="_blank" rel="noopener noreferrer nofollow" class="external-link">Prof. Dr. Claus Schuster</a> .</p>
        <h4>Haftungshinweis:</h4>
        <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
        <h4>Design und technische Realisierung:</h4>
        <p>leycom - media solutions<br>Möhnestraße 55<br>59755 Arnsberg<br>Telefon: +49 29 32 89 59 45<br>Telefax: +49 29 32 89 59 46<br>E-Mail: info@leycom.de<br>Internet: https://www.leycom.de</p>                    
    </div>
</template>

<script>
    export default {
        data: function(){
            return{}
        }
    }
</script>
<template>
     <tr>
        <th scope="row">{{survey.order}}</th>
        <td>
            <a href="#" v-on:click="navTo" v-bind:class="[survey.available ? 'disabled' : '']">{{survey.title}}</a>
        </td>
        <td>
            <font-awesome-icon class="text-secondary" v-bind:class="[survey.available ? 'text-success' : 'text-secondary']" icon="circle" />            
        </td>
        <td>
            <font-awesome-icon class="text-secondary" v-bind:class="[survey.done ? 'text-success' : 'text-secondary']" icon="circle" /> 
        </td>
        <td>
            <font-awesome-icon class="text-secondary" v-bind:class="getReviewedClass" icon="circle" /> 
        </td>
        <td>{{surveyScore}} / {{maxScore}}</td>
    </tr>
</template>
<script>
    export default {
        props: ['survey'],
        data: function(){
            return {              
                maxScore: 100
            }
        },
        methods: {
            navTo: function(){
                if(this.survey.available !== true) return;                
                window.location.href = `/survey/${this.survey.id}`;
            }
        },
        computed: {
            getReviewedClass: function(){
                switch(this.survey.accepted){
                    case 2: return 'text-danger';
                    case 1: return 'text-success';
                    default: return 'text-secondary';
                }
            },
            surveyScore: function(){
                if(typeof this.survey.score === "number" && this.survey.score > 0){
                    return this.survey.score;
                } else {
                    return "-";
                }
            }
        }   
    }
</script>
<template>
    <div>
        <p v-if="id === 1">
            Bitte erkläre uns deine Idee in wenigen Sätzen. Warum und für wen ist deine Idee relevant?  Du kannst deine Idee als Kurzbeschreibung (max. 100 Wörter) oder Video (max. 150 MB im Dateiformat .mp4) in Deutsch oder Englisch einreichen.
        </p>
        <!-- <p v-if="id === 2">
            In dieser Stufe solltest du den Aufbau deines Geschäftsmodells entwickeln. Hier werden deine Schlüsselressourcen, -Aktivitäten und -Partner festgelegt, sodass du weißt mit welchem Team du durchstartest und welche ersten Kontakte als Berater oder zur Finanzierungsunterstützung mit an Bord sind.
        </p>
        <p v-if="id === 3">
            Im Rahmen der Validierungsphase solltest du nach Bestätigung des Interesses an deinem Produkt und dem Geschäftsmodell suchen. Die Aufmerksamkeit deiner Zielgruppe sollte sich demnach in einem ersten Nutzerwachstum, dem Produkt-Markt-Fit wie auch der Zahlungsbereitschaft widerspiegeln.
        </p>
        <p v-if="id === 4">
            In der Phase "Effizienz" liegt der Fokus auf der Verfeinerung deines Geschäftsmodells und der Steigerung deiner Kundenakquise, sodass du dich von deinen Wettbewerbern abheben kannst. Ein virales Wachstum erreichst du, indem du die Nutzererfahrungen und Netzwerkeffekte repetitiv überarbeitest.
        </p>
        <p v-if="id === 5">
            In dieser Stufe gilt es dein Wachstum aggressiv voranzutreiben. 
        </p> -->
    </div>
</template>
<script>
    export default {
        data: function(){
            return{
                id: 0
            }
        },
        beforeMount: function(){
            const id = parseInt(this.$route.params.id);
            if(!isNaN(id)) this.id = id;           
        }
    }
</script>

//npm install bootstrap jquery popper.js
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import checkLogin from './scripts/checkLogin'

//npm install axios

// Add font awesome
// npm i --save @fortawesome/free-brands-svg-icons
// npm i --save @fortawesome/free-regular-svg-icons
// npm install --save @fortawesome/fontawesome-svg-core @fortawesome/free-solid-svg-icons @fortawesome/vue-fontawesome @popperjs/core
import { library} from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faUser, faSignOutAlt, faBars, faUserShield, faCheckCircle, faMinusCircle, faTimesCircle, faPlayCircle, faStopCircle, faCircle, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faEnvelope, faUser, faSignOutAlt, faBars, faUserShield, faCheckCircle, faMinusCircle, faTimesCircle, faPlayCircle, faStopCircle, faCircle, faPowerOff);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresLogin)){
    checkLogin(router, store).then(
      () => {
        if(to.matched.some(record => record.meta.requiresAdmin)){
          if(store.state.login.isAdmin === true){
            next();
          } else {
            if(to.name !== 'Home'){
              next({name: 'Home'});
            } else {
              next(false);
            }
          }          
        } else {
          next();
        }
      },
      () => {
        if(to.name !== 'Home'){
          next({name: 'Home'});
        } else {
          next(false);
        }
      }
    )
  } else {
    next();
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: {
      loggedIn: false,
      isAdmin: false     
    },
    user: {
      name: "Unknown",
      email: "Unknown",
      project: "Unknown"
    },  
    alert: {
      show: false,
      message: "help",
      type: "alert-danger",
      time: 6000
    } 
  },
  getters: {
    isLoggedIn: function(state){      
      return state.login.loggedIn;
    },
    isAdmin: function(state){      
      return state.login.isAdmin === true && state.login.loggedIn === true;
    },
    getUsername: function(state){
      return state.user.name;
    },
    getEmail: function(state){
      return state.user.email;
    },
    getAlertStatus: function(state){
      return state.alert.show;
    },
    getAlertMessage: function(state){
      return state.alert.message;
    },
    getAlertType: function(state){
      return state.alert.type;
    },
    getAlertTime: function(state){
      return state.alert.time;
    }
  },
  mutations: {
    // TODO
    loginUser: function(state, {email, name, admin, project}){
      state.user.email = email,
      state.user.name = name,
      state.user.project = project,
      state.login.loggedIn = true,
      state.login.isAdmin = admin           
    },
    logoutUser: function(state){
      state.user.email = "",
      state.user.name = "",
      state.user.project = "",
      state.login.loggedIn = false,
      state.login.isAdmin = false      
    },
    showAlert: function(state, {message, time}){
      state.alert.type = "alert-danger";
      state.alert.show = true;
      state.alert.message = message;
      window.scrollTo(0,0);
      if(time) new Promise((resolve) => setTimeout(resolve, state.alert.time)).then(()=>{state.alert.show=""; state.alert.message=""; state.alert.type="alert-danger"});      
    },
    showWarning: function(state, {message, time}){
      state.alert.type = "alert-danger";
      state.alert.show = true;
      state.alert.message = message;
      window.scrollTo(0,0);
      if(time) new Promise((resolve) => setTimeout(resolve, state.alert.time)).then(()=>{state.alert.show=""; state.alert.message=""; state.alert.type="alert-danger"});  
    },
    showSuccess: function(state, {message, time}){
      state.alert.type = "alert-success";
      state.alert.show = true;
      state.alert.message = message;
      window.scrollTo(0,0);
      if(time) new Promise((resolve) => setTimeout(resolve, state.alert.time)).then(()=>{state.alert.show=""; state.alert.message=""; state.alert.type="alert-danger"});  
    },
    showNotification: function(state, {message, time}){
      state.alert.type = "alert-info";
      state.alert.show = true;
      state.alert.message = message;
      window.scrollTo(0,0);
      if(time) new Promise((resolve) => setTimeout(resolve, state.alert.time)).then(()=>{state.alert.show=""; state.alert.message=""; state.alert.type="alert-danger"});  
    },
    resetNotification: function(state){
      state.alert.show=""; 
      state.alert.message=""; 
      state.alert.type="alert-danger"
    }    
  },
  actions: {
  },
  modules: {
  }
})

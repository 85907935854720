<template>
    <div>
        <h2>Datenschutzerklärung unserer Webseite checker.mammut-startupfestival.de und des Umfrage-Tools „Mammut-Checker“</h2>
        <h4>A. Zweck der Verarbeitung</h4>
        <p>Der Mammut-Checker ist ein Umfrage-Tool und dient Ihnen dazu, Ihre Ideenfindung durch unsere Betreuung zu optimieren. Hierfür müssen Sie sich zu Beginn registrieren, indem Sie ein Benutzerkonto anlegen. Die Registrierung dient nicht zum Abschluss eines Vertrages mit Ihnen, sondern ist für die Bereitstellung und Nutzung des Umfragetools und des unverbindlichen Betreuungsangebotes durch uns erforderlich</p>
        <h4>B. Personenbezogene Daten</h4>
        <p>Bei der Registrierung und der weiterführenden Nutzung im Betreuungsprozess des Mammut-Checkers werden folgende personenbezogene Daten verarbeitet:</p>
        <ul>
            <li>Datum und Uhrzeit des Zugriffs und des ersten Zugriffs</li>
            <li>Angaben in den hinterlegten Fragebögen bzgl. der pitch-bezogenen Projektidee</li>
            <li>E-Mail-Adresse</li>
            <li>Vorname</li>
            <li>Projektname</li>
            <li>Ihr selbstgewähltes Passwort für das Benutzerkonto, welches uns jedoch nicht bekannt ist</li>
            <li>Projektbezeichnung</li>
            <li>Session-ID-Cookie (Benutzer-Session)</li>
        </ul>
        <p>Nach der Registrierung werden bei der Nutzung des Mammut-Checkers darüber hinaus folgende Daten verarbeitet:</p>
        <ul>
            <li>eine von Ihnen bereitgestellte Datei, die einen Pitch zu Ihrem Projekt enthält. Hier ist Ihre Kreativität gefragt, sodass wir im Vorfeld nicht sagen können, welche weiteren personenbezogenen Daten von Ihnen durch diesen Pitch an uns weitergegeben werden. Daher achten Sie bitte darauf, diesen Pitch so datenneutral wie möglich, d. h., möglichst ohne für das Projekt nicht relevante personenbezogene Daten, die über die oben genannten hinausgehen, einzureichen. Insbesondere sollte diese Datei keine personenbezogenen Daten Dritter oder gar sensiblen Daten Dritter nach Art. 9 Abs. 1 Datenschutz-Grundverordnung (DSGVO) enthalten</li>
            <li>die von Ihnen eingestellten Antworten zu Ihrem Projekt in dem jeweiligen Fragebogen</li>
            <li>Zeitpunkt des letzten Logins (Datum und Uhrzeit)</li>
        </ul>
        <h4>C. Rechtsgrundlage für die Datenverarbeitung</h4>
        <p>Die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten bei Aufruf unserer Webseite ergibt sich aus Art. 6 Abs. 1 Uabs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien Bereitstellung seiner Dienste, da einige Funktionen ansonsten nicht angeboten werden können. Mit dem Einloggen stimmen Sie der Nutzung von technisch notwendigen Cookies gem. Art. 6 Abs. 1Uabs. 1 lit. a DSGVO zu.<br>Die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten, die für das Anlegen des Benutzerkontos und im Rahmen der Nutzung des Mammut-Checkers erforderlich sind, ergibt sich aus Ihrer Einwilligung (Art. 6 Abs. 1 Uabs. 1 Buchst. A DSGVO).</p>
        <h4>D. Verantwortlicher</h4>
        <p>Der Verantwortliche im Sinne des Art. 4 Nr. 7 DSGVO ist die Fachhochschule Südwestfalen, Baarstraße 6, 58636 Iserlohn. Die Fachhochschule Südwestfalen hat folgende Person zum Datenschutzbeauftragten bestellt:</p>
        <p>Fachhochschule Südwestfalen<br>
        Vladimir Denisenko<br>
        Datenschutzbeauftragter<br>
        Baarstr. 6<br>
        58636 Iserlohn<br>
        +49 (0) 2371 566 5703<br>
        E-Mail: <a href="mailto:datenschutzbeauftragter@fh-swf.de">datenschutzbeauftragter@fh-swf.de</a></p>
        <h4>E. Empfänger der Daten und Umfang der Verarbeitung</h4>
        <p>Wir verarbeiten Ihre Daten nur, soweit es zur Bereitstellung des Mammut-Checkers und dem damit verfolgten Zweck erforderlich ist. Bei diesen Vorgängen werden die datenschutzrechtlichen Bestimmungen der DSGVO sowie die des Landes NRW eingehalten. Der Mammut-Checker und Ihre personenbezogenen Daten werden auf unseren Servern in Deutschland verarbeitet. Lediglich die Mitglieder des Teams Fhpreneur erhalten und verarbeiten Ihre Daten. Eine Weitergabe der Daten an weitere Personen innerhalb der Fachhochschule Südwestfalen, die nicht am dem Mammut-Checker beteiligt sind, oder an Dritte erfolgt nicht, es sei denn, es besteht eine gesetzliche Verpflichtung zur Weitergabe Ihrer Daten an andere Stellen.</p>
        <h4>F. Speicherdauer und Löschung</h4>
        <p>
            Die Logfiles, die mit dem Aufrufen der Webseite verarbeitet werden, werden nicht dauerhaft gespeichert, sondern nach Ablauf des 28.02.2022 gelöscht.
            <br>
            Ihre personenbezogenen Daten, die Sie bei der Registrierung und Nutzung des Mammut-Checkers zur Verfügung stellen, werden für die Dauer Ihres Projekts gespeichert und verarbeitet und nach der Beendigung spätestens bis zum 28.02.2022 gelöscht. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber gesetzlich angeordnet ist.
            <br>
            Die uns von Ihnen für Ihren Pitch zur Verfügung gestellte Datei mit den hierin enthaltenen personenbezogenen Daten werden wir nach der Betrachtung archivieren und mit Ablauf des 12.01.2022 löschen.
        </p>
        <h4>G. Betroffenenrechte</h4>
        <p>
            Nach der Datenschutzgrundverordnung stehen Ihnen als Person, deren personenbezogene Daten verarbeitet werden, besondere Rechte zu, die Sie gegenüber dem Websitebetreiber geltend machen können. Um Ihre Rechte uns gegenüber geltend zu machen oder für weitere Fragen zum Thema Datenschutz, können Sie sich an uns wenden:
        </p>
        <p>
            leycom-media solutions
            <br>
            Möhnestraße 55
            <br>
            59755 Arnsberg
            <br>
            Telefon: +49 (0) 2932 895945
            <br>
            E-Mail: <a href="mailto:info@leycom.de">info@leycom.de</a>
            <br>
            Internet: <a href="https://leycom.dee">https://leycom.de</a>
        </p>
        <h5>I.	Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>
        <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung (Art. 6 Abs. 1 UAbs. 1 lit. a DSGVO) möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
        <h5>II.	Auskunft, Berichtigung und Löschung</h5>
        <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über die Kategorien Ihrer gespeicherten personenbezogenen Daten, deren Herkunft, die Kategorien der Empfänger, die voraussichtliche Speicherdauer und den Zweck der Datenverarbeitung. Weiterhin steht Ihnen gegebenenfalls das Recht auf Berichtigung/Vervollständigung Ihrer Daten, sofern diese unrichtig oder unvollständig sind, oder Löschung Ihrer Daten zu.</p>
        <h5>III. Recht auf Einschränkung der Verarbeitung</h5>
        <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
        <ul>
            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig ist, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
        </ul>
        <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
        <h5>IV.	Recht auf Datenübertragbarkeit</h5>
        <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
        <h5>V.	Widerspruchsrecht</h5>
        <p>Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 UAbs. 1 lit. e oder f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten, Widerspruch einzulegen, dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO). Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, so haben sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</p>
        <h5>H.	Beschwerderecht</h5>
        <p>Zusätzlich zu den o. g. Rechten haben Sie das Recht, sich bei einer Datenschutzbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes über mögliche Datenschutzverstöße zu beschweren. Zuständig für die Fachhochschule Südwestfalen ist die Landesbeauftragte für Datenschutz und Informationsfreiheit des Landes NRW (<a href="www.ldi.nrw.de">www.ldi.nrw.de</a>). </p>
    </div>
</template>

<script>
    export default {
        data: function(){
            return{}
        }
    }
</script>
<template>
    <div class="form-group">
        <label style="font-style:italic;">[{{data.id}}]: {{data.text}}</label>
        <div v-for="(option, index) in data.choices" v-bind:key="index" class="form-check">
            <input class="form-check-input" type="checkbox" v-bind:value="option.id" v-model="value" v-bind:id="data.id+'-'+option.id" v-bind:disabled="readonly">
            <label class="form-check-label" v-bind:for="option.id">
            {{option.text}}
            </label>
        </div>      
  </div>  
</template>

<script>
import axios from "../axios";
import msg from "../messages";
import checkLogin from "../scripts/checkLogin";

export default {    
    props: ["data", "readonly", "userId", "submissionId"],
    data: function(){
        return {  
            value: []      
        }
    },
    beforeMount: async function(){        
        if(this.data.id) axios.post('getChoice', {question: this.data.id, userId: this.userId, sid:this.submissionId}).then(
            (res) => {
                if(res.status === 200 && typeof res.data.value !== "undefined"){
                    if(typeof res.data.value === "object" && res.data.value !== null){
                        this.value = res.data.value;
                    }                  
                } else {
                     this.$store.commit("showAlert", {message: msg.survey.couldNotLoad, time:true});
                }
            },
            () => {
                this.$store.commit("showAlert", {message: msg.survey.couldNotLoad, time:true});
            } 
        )
    },
    watch: {
        value: async function(){     
            await checkLogin(this.$router, this.$store); 
            if(this.readonly === true) return;                  
            if(typeof this.value === "object"){                            
                axios.post('setChoice', {question: this.data.id, value: this.value}).then(
                    (response) => {
                        if(response.status !== 200 || response.data.commit !== "accepted"){
                            this.$store.commit("showAlert", {message: msg.survey.couldNotSave, time:true});
                        }
                    },
                    () => {
                        this.$store.commit("showAlert", {message: msg.survey.couldNotSave, time:true});
                    }
                )
            }
        }
    }
}
</script>

<style scoped>
    label {
        font-style: normal;
        user-select: none;
    }
</style>

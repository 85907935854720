<template>
    <div>
        <div class="form-group" v-if="!readonly">
            <label>Einreichung Deiner Idee in Form einer Kurzbeschreibung oder eines Videos</label>                        
            <input type="file" ref="file" class="form-control-file" v-on:change="submit" v-bind:disabled="readonly">     
            <small>Erlaubte Dateiformate: mpeg, mp4, pdf, doc und docx <br />Erlaubte max. Dateigröße: 150 MB</small>       
        </div>
        <div v-if="readonly">
            <h4>Datei</h4>
            <a href="#" v-on:click="getDownload">{{this.link}}</a>
        </div>
    </div>
</template>
<script>
    import axios from '../axios';
    import msg from "../messages";
    import checkLogin from "../scripts/checkLogin";
    export default {
        props: ["readonly", "userId", "submissionId"],
        data: function () {
            return {
                link: ''
            };
        },
        beforeMount: async function(){            
            try{
                const file = await axios.post('getFile', {member: this.userId, sid:this.submissionId}); 
                if(file.status === 200 && typeof file.data.file === "string"){
                    this.link = file.data.file;
                }                             
            } catch(error){
                this.$store.commit('showWarning', {message: msg.unknown, time:true});
            }
        },
        methods: {
            getDownload: async function(){
                try{
                    if(typeof this.link !== "string" || this.link.length <= 0) throw Error("No input");                   
                    const d = await axios.post("getFileRaw", {link: this.link}, {responseType: 'blob'});                    
                    var fileURL = window.URL.createObjectURL(new Blob([d.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', this.link);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                } catch(error){
                    this.$store.commit("showAlert", {message: msg.unknown, time:true});
                }
            },
            submit: async function(){
                await checkLogin(this.$router, this.$store);
                if(this.readonly === true) return;
                const file = this.$refs.file.files[0]; 
                const formData = new FormData();
                formData.append('file', file);
                if(typeof file === "object"){
                    axios.post('uploadFile', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(
                        (resolve) => {                            
                            if(resolve.status === 200 && resolve.data.received === true){                                
                                this.$store.commit("showSuccess", {message: msg.upload.success, time:true});
                            } else {
                                if(resolve.data.error === 'FILESIZE'){
                                    this.$store.commit("showAlert", {message: msg.upload.size, time:true});
                                } else if(resolve.data.error === 'FILEFORMAT'){
                                    this.$store.commit("showAlert", {message: msg.upload.format, time:true});
                                } else {
                                    this.$store.commit("showAlert", {message: msg.upload.error, time:true});
                                }                                
                            }
                        },
                        () => {
                            this.$store.commit("showAlert", {message: msg.upload.error, time:true});
                        }
                    )
                }         
            }
        }
    }
</script>
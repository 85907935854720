import Vue from 'vue'
import VueRouter from 'vue-router'

// Views
import Home from '../views/home.vue'
import Register from '../views/register.vue'
import Reset from '../views/reset.vue'
import Overview from '../views/overview.vue'
import PageNotFound from '../views/notfound.vue'
import Profile from '../views/profile.vue'
import Survey from '../views/survey.vue'
import Admin from '../views/admin.vue'
import About from '../views/about.vue'
import Verify from '../views/verify.vue'
import Login from '../views/login.vue'
import Privacy from "../views/privacy.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      requiresLogin: false,
      requiresAdmin: false
    },   
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresLogin: false,
      requiresAdmin: false
    },   
    component: Login
  },
  {
    path: '/admin',
    name: 'Administration',   
    meta: {
      requiresLogin: true,
      requiresAdmin: true
    },
    component: Admin
  },
  {
    path: '/register',
    name: 'Register',   
    meta: {
      requiresLogin: false,
      requiresAdmin: false
    },
    component: Register
  },
  {
    path: '/profile',
    name: 'Profile',  
    meta: {
      requiresLogin: true,
      requiresAdmin: false
    },
    component: Profile
  },
  {
    path: '/reset',
    name: 'Reset', 
    meta: {
      requiresLogin: false,
      requiresAdmin: false
    },  
    component: Reset
  },  
  {
    path: '/privacy',
    name: 'Privacy',   
    meta: {
      requiresLogin: false,
      requiresAdmin: false
    },
    component: Privacy
  },
  {
    path: '/about',
    name: 'About',    
    meta: {
      requiresLogin: false,
      requiresAdmin: false
    },
    component: About
  },
  {
    path: '/overview',
    name: 'Overview',  
    meta: {
      requiresLogin: true,
      requiresAdmin: false
    }, 
    component: Overview
  },
  {
    path: '/verify/:id', // $route.params.id 
    name: 'Verify',   
    meta: {
      requiresLogin: false,
      requiresAdmin: false
    },
    component: Verify
  }, 
  {
    path: '/survey/:id/:uid?/:sid?', // $route.params.id 
    name: 'Survey',   
    meta: {
      requiresLogin: true,
      requiresAdmin: false
    },
    component: Survey
  },  
  {
    path: '*',
    name: 'NotFound',
    meta: {
      requiresLogin: false,
      requiresAdmin: false
    },
    component: PageNotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="form-group">
    <label>[{{ data.id }}]: {{ data.text }}</label>
    <br />
    <div class="btn-group btn-group-toggle" data-toggle="buttons">      
      <label class="btn text-white" v-bind:class="[getValue === 0 ? 'active' : '']" v-on:click.prevent="setValue(0)">
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"                            
        />
        0
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 1 ? 'active' : '']" v-on:click.prevent="setValue(1)">
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"                                                
        />
        1
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 2 ? 'active' : '']" v-on:click.prevent="setValue(2)" >
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"                            
        />
        2
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 3 ? 'active' : '']" v-on:click.prevent="setValue(3)">
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"                         
        />
        3
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 4 ? 'active' : '']" v-on:click.prevent="setValue(4)">
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"
                  
        />
        4
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 5 ? 'active' : '']" v-on:click.prevent="setValue(5)" >
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"                  
        />
        5
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 6 ? 'active' : '']" v-on:click.prevent="setValue(6)">
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"
                   
        />
        6
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 7 ? 'active' : '']" v-on:click.prevent="setValue(7)"  >
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"                 
        />
        7
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 8 ? 'active' : '']" v-on:click.prevent="setValue(8)">
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"                    
        />
        8
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 9 ? 'active' : '']" v-on:click.prevent="setValue(9)">
        <input
          type="radio"
          v-bind:name="data.id"
          autocomplete="off"                 
        />
        9
      </label>
      <label class="btn text-white" v-bind:class="[getValue === 10 ? 'active' : '']" v-on:click.prevent="setValue(10)">
        <input
          type="radio"
          name="options"
          autocomplete="off"                    
        />
        10
      </label>
    </div>
    <p class="pt-1">Deine Auswahl: {{ value }}</p>
  </div>
</template>

<script>
import axios from "../axios";
import msg from "../messages";
import checkLogin from "../scripts/checkLogin"
export default { 
  props: ["data", 'readonly', "userId", 'submissionId'],
  data: function () {
    return {
      value: null,
    };
  }, 
  methods: {
    setValue: async function (value) {  
        await checkLogin(this.$router, this.$store); 
        if(this.readonly === true) return;       
        this.value = value;    
        axios.post('setChoice', {question: this.data.id, value: [value]}).then(
          (response) => {
            if(response.status !== 200 || response.data.commit !== "accepted"){
              this.$store.commit("showAlert", {message: msg.survey.couldNotSave, time:true});
            }
          },
          () => {
            this.$store.commit("showAlert", {message: msg.survey.couldNotSave, time:true});
          }
        );               
    },
  },
  computed: {
    getValue: function(){        
        return this.value;
    }
  },
  beforeMount: async function(){
      try{          
        if(this.data.id) axios.post('getChoice', {question: this.data.id, userId: this.userId, sid:this.submissionId}).then(
            (res) => {                
                if(res.status === 200 && typeof res.data.value !== "undefined"){                  
                    if(typeof res.data.value === "object" && res.data.value !== null && typeof res.data.value[0] === "number"){
                        this.value = res.data.value[0];
                    }                  
                } else {
                     this.$store.commit("showAlert", {message: msg.survey.couldNotLoad, time:true});
                }
            },
            () => {
                this.$store.commit("showAlert", {message: msg.survey.couldNotLoad, time:true});
            } 
        )
      } catch(error){
         this.$store.commit("showAlert", {message: msg.unknown, time:true});
      }
    },   
};
</script>

<style>
label {
  font-style: italic;
}
div.btn-group {
  background: linear-gradient(to right, #003366, #993366);
}
label.active {
  border-color: #ffcc33;
  border-width: 2px;
  border-style: solid;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>


<template>
    <div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb" style="background-color: white; margin-left: 0px; padding-left: 0px;">
                <li class="breadcrumb-item"><a href="#" v-on:click="backToOverview">Von der Idee zum Startup</a></li>
                <li class="breadcrumb-item active" aria-current="page">Fragebogen "{{data.title}}"</li>
            </ol>
        </nav>
        <SurveyText />
        <div v-if="loaded">           
            <template v-for="(child, index) in data.questions">      
                <component v-bind:is="child.type" v-bind:key="index" v-bind:data="child" v-bind:readonly="readonly" v-bind:userId="userId" v-bind:submissionId="submissionId"></component>
            </template>
            <button v-if="!readonly" type="button" class="btn btn-primary mt-2" v-on:click="submit">Absenden</button>
        </div>
    </div>
</template>
<script>
    import axios from '../axios';
    import msg from "../messages";
    import free_text from "../components/text";
    import single_choice from "../components/singlechoice.vue";
    import multiple_choice from "../components/multiplechoice.vue";
    import slider from "../components/slider.vue";
    import upload from "../components/upload.vue";
    import checkLogin from "../scripts/checkLogin";
    import SurveyText from "../components/surveyText.vue";

    export default {
        data: function(){
            return {
                userId: null,
                loaded: false,
                submissionId: null,
                data: []
            }
        },
        beforeCreate: async function(){
            try{      
                const id = parseInt(this.$route.params.id);
                const uid = parseInt(this.$route.params.uid);
                const sid = parseInt(this.$route.params.sid);       
                await checkLogin(this.$router, this.$store);                
                if(!isNaN(sid)){
                    this.submissionId = sid;
                }
                if(!isNaN(uid)) this.userId = uid;              
                if(typeof id !== "number" || id < 0) this.$router.push({path: '/overview'});             
                axios.post(`survey/${id}`, {userId: this.userId}).then(
                    (resolve) => {                        
                        if(resolve.status === 200 && typeof resolve.data.survey === "object" && resolve.data.survey !== null){
                            this.data = resolve.data.survey;                   
                            this.loaded = true;
                        } else {
                            this.$store.commit("showAlert", {message: msg.survey.error, time:true});                        
                        }
                    },
                    () => {
                        this.$store.commit("showAlert", {message: msg.survey.error, time:true});  
                    }
                );
            } catch(error){
                this.$store.commit("showAlert", {message: msg.survey.error, time:true});
            }
        },
        components: {
            free_text,
            single_choice,
            multiple_choice,
            slider,
            upload,
            SurveyText
        },
        computed: {
            readonly: function(){
                return this.userId !== null;
            }
        },
        methods: {
            backToOverview: function(){
                window.location.href='/overview'
            },
            submit: async function(){     
                await checkLogin(this.$router, this.$store);         
                if(this.userId !== null) return;
                if(typeof this.data.id === "number" && this.data.id > 0){
                    axios.post('submitSurvey', {survey_id: this.data.id}).then(
                        (resolve) => {                            
                            if(resolve.status === 200 && typeof resolve.data.survey === "string"){
                                if(resolve.data.survey === 'ok'){
                                    this.$store.commit('showSuccess', {message: msg.submitSurvey.success, time:false});                                    
                                    setTimeout(                                        
                                        () => {                                            
                                            this.$store.commit('resetNotification');
                                            this.$router.push({ path: "/overview" });
                                        },
                                        3000
                                    );                                 
                                } else {
                                    if(msg.submitSurvey[resolve.data.survey]){
                                        this.$store.commit('showWarning', {message: msg.submitSurvey[resolve.data.survey], time:true});
                                    } else {
                                        this.$store.commit('showAlert', {message: msg.submitSurvey.error, time:true});
                                    }
                                }
                                
                            } else {
                                this.$store.commit('showAlert', {message: msg.submitSurvey.error, time:true});
                            }                        
                        },
                        () => {
                            this.$store.commit('showAlert', {message: msg.submitSurvey.error, time:true});                            
                        }
                    )
                }
                
            }
        }
    }
</script>
<template>
    <div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="project">Projektname</label>
                <input type="text" v-model="project" class="form-control" placeholder="Dein Projektname"/>
            </div>            
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="name">Vorname</label>
                <input type="text" v-model="name" class="form-control" placeholder="Dein Vorname"/>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="email">E-Mail-Adresse</label>
                <input type="text" v-model="email" class="form-control" placeholder="Deine E-Mail-Adresse" autocomplete="off" />
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label for="password">Passwort</label>
                <input type="password" v-model="password" class="form-control" placeholder="(Optional)" />
            </div>
        </div>
        <div class="form-group px-1 mt-2">
            <button type="button" v-on:click="submit" class="btn btn-primary">Absenden</button>
        </div>
    </div>
</template>
<script>
    import axios from '../axios'
    import msg from '../messages'
    import crypto from "crypto";
    import checkLogin from "../scripts/checkLogin";

    export default {
        data: function(){
            return {
                project: '',
                name: '',
                email: '',
                password: ''
            }
        },
        beforeCreate: async function(){
            try{
                await checkLogin(this.$router, this.$store);  
                const data = await axios.post('userData', {});                
                if(data.status !== 200 || typeof data.data !== "object") throw new Error('No data');
                this.project = data.data.project;
                this.name = data.data.name;
                this.email = data.data.email;
                this.password = '';
            } catch(error){
                this.$store.commit('showWarning', {message: msg.noconnection, time:true});
                return;
            }
            
        },
        methods: {
            submit: async function(){
                try{
                    await checkLogin(this.$router, this.$store);
                    const out = {
                    project: this.project,
                    name: this.name,
                    email: this.email
                    }
                    if(this.password.length > 0){
                        const hash = crypto.createHash("sha512");
                        out.password = hash.update(this.password, "utf-8").digest("hex");
                    }
                    const ret = await axios.post('updateUserData', out);
                    if(ret.status !== 200 || typeof ret.data.admin !== "boolean" || typeof ret.data.name !== "string" || typeof ret.data.email !== "string" || typeof ret.data.project !== "string") throw new Error("Missing");
                    this.$store.commit("loginUser", {admin: ret.data.admin, name: ret.data.name, email: ret.data.email, project: ret.data.project});
                    this.$store.commit('showSuccess', {message: msg.changed, time:true});
                } catch(error){
                    this.$store.commit('showWarning', {message: msg.noconnection, time:true});
                    return;
                }               
            }
        }
    }
</script>